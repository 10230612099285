import { useEffect, useState } from 'react'

import GraphQLRequest from 'Clutch/Utilities/GraphQLClient/GraphQLClient'

import { getFeaturedProductsQuery } from '../../graphql/getFeatuerdProducts'
import { convertToRecommendationBlock } from '../../utils/featuredProductsUtils'

const useFeaturedProducts = (products) => {
  const [isLoading, setIsLoading] = useState(false)
  const [featuredProducts, setFeaturedProducts] = useState([])

  useEffect(() => {
    const getFeaturedProducts = async (skus) => {
      setIsLoading(true)
      const variables = {
        skus,
      }
      try {
        const response = await GraphQLRequest(
          '/graphql/products',
          getFeaturedProductsQuery,
          variables,
        )

        // deduplication
        let bases = []
        let variants = []
        if (response.bases.length === response.variants.length)
          variants = [...response.variants]
        else if (response.bases.length > response.variants.length)
          bases = [...response.bases]

        const recommendationSection = convertToRecommendationBlock(
          bases,
          variants,
        )
        setFeaturedProducts(recommendationSection)
        setIsLoading(false)
      } catch (err) {
        setFeaturedProducts([])
        setIsLoading(false)
        console.error(err)
      }
    }
    if (products && products.length > 0) {
      getFeaturedProducts(products)
    } else {
      setFeaturedProducts([])
    }
  }, [products])

  return { isLoading, featuredProducts }
}

export default useFeaturedProducts
