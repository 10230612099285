const getFeaturedProductsQuery = `query GetFeaturedProductsForUGC($skus: [String] = null) {
  bases: skuBases(skuBaseNumbers: $skus) {
    ...skuBaseDetails
  }
  variants: skuVariants(skuVariantNumbers: $skus) {
    ...skuVariantDetails
    skuBase  {
      ...skuBaseDetails
    }
  }
}

fragment skuBaseDetails on SkuBasePublicDM {
  displayTitle
  skuBaseNumber
  productPageUrl
  thumbnailImageLink
  rating
  pricing {
    salePrice
    salePriceString
  }
  skuVariantSummaries {
    skuNumber
  }
  segmentCategorization
  brand
}

fragment skuVariantDetails on SkuVariantPublicDM {
  displayTitle
  skuVariantNumber
  kit {
    skuBaseComponents {
      kitSkuNumber
    }
    skuVariantComponents {
      kitSkuNumber
    }
  }
}
`

export { getFeaturedProductsQuery }
