import { useState } from 'react'
import { useInView } from 'react-intersection-observer'

import ArticleCard from 'Clutch/Containers/ArticleCard'
import classNames from 'classnames'
import Image from 'next/image'

import { createArticleListViewedSegmentEvent } from 'Clutch/Utilities/Instrumentation/Articles/articleSegmentEvents'
import { createClickedSegmentEvent } from 'Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents'

import useSegment from 'Hooks/useSegment/useSegment'

import Carousel from 'Clutch/Organisms/Carousel'

import Button from 'Clutch/Molecules/Button'

import Gutters from 'Clutch/Atoms/Gutters'
import Panel from 'Clutch/Atoms/Panel'
import Skeleton from 'Clutch/Atoms/Skeleton'
import Typography from 'Clutch/Atoms/Typography'

import modularSectionTypes from '../../../constants/modularSectionTypes'
import { useHomePage } from '../../../providers/HomePageProvider'
import styles from './ToolboxSection.module.scss'

const getImageFileName = (audience) => {
  switch (audience) {
    case 'race':
      return 'new_toolbox_bg_race.png'
    case 'street':
      return 'new_toolbox_bg_street.png'
    default:
      return 'new_toolbox_bg_default.png'
  }
}

const getSeeAllArticlesHref = (audience) => {
  switch (audience) {
    case 'race':
      return '/the-toolbox/racing~8388608-2'
    case 'street':
      return '/the-toolbox/street~8388608-1'
    default:
      return '/the-toolbox'
  }
}

const ToolboxSection = ({ audience }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: '200px 0px',
  })

  return (
    <>
      <div ref={ref} />
      <Panel layer={0} inverseTheme className={styles.background}>
        <Gutters noVerticalPadding>
          <div className={styles.imageContainer}>
            <>
              {!inView ? (
                <div width={1440} height={448} className={styles.skeleton} />
              ) : (
                <Image
                  className={styles.image}
                  src={`https://content.speedwaymotors.com/OtherImages/${getImageFileName(
                    audience,
                  )}`}
                  width={1440}
                  height={448}
                  sizes={'100vw'}
                  alt=""
                />
              )}

              <div className={styles.contentContainer}>
                {!inView ? (
                  <div
                    width={250}
                    height={65}
                    className={styles.toolboxTitle}
                  />
                ) : (
                  <Image
                    src={`https://content.speedwaymotors.com/HomePageImages/ToolboxLogo.png`}
                    width={250}
                    height={65}
                    className={styles.toolboxTitle}
                    alt="The Toolbox"
                  />
                )}
                <p
                  className={classNames(
                    'p p-1 p-color-contrast p-bold',
                    styles.description,
                  )}
                >
                  Expert advice from seasoned gearheads.
                </p>
                <div className={styles.buttonContainer}>
                  <Button
                    className={styles.button}
                    brand={'secondary'}
                    segmentEvent={createClickedSegmentEvent('SeeAllArticles')}
                    size={'large'}
                    text={'All Articles'}
                    href={getSeeAllArticlesHref(audience)}
                  >
                    <Typography size={1} font={'bold'}>
                      All Articles
                    </Typography>
                  </Button>
                </div>
              </div>
            </>
          </div>

          <ArticleRecommendations inView={inView} />
        </Gutters>
      </Panel>
    </>
  )
}

const ArticleRecommendations = ({ inView }) => {
  const [eventSent, setEventSent] = useState(false)
  const { sendCustomSegmentTrackEvent } = useSegment()
  const { modularSections } = useHomePage()

  const toolboxSection = modularSections.find(
    (x) => x.type === modularSectionTypes.toolbox,
  )

  const articles = toolboxSection.articles
  const articlesEvent = createArticleListViewedSegmentEvent(
    articles,
    'Homepage Toolbox Section',
  )

  if (!eventSent && inView) {
    sendCustomSegmentTrackEvent(articlesEvent)
    setEventSent(true)
  }

  return (
    <Carousel
      id={'toolbox_recommendations_carousel'}
      leftAlignCarousel
      inverseTheme
      isTransparentBackground
    >
      {(articles || []).map((article, i) => {
        return (
          <Carousel.Item key={article.id}>
            <div className={styles.reducedArticleCard}>
              {!inView ? (
                <>
                  <Skeleton width={248} height={165} />
                  <Skeleton width={248} height={24} />
                </>
              ) : (
                <ArticleCard
                  article={article}
                  useReducedArticleCard
                  border={false}
                  index={i}
                />
              )}
            </div>
          </Carousel.Item>
        )
      })}
    </Carousel>
  )
}

export default ToolboxSection
